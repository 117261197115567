import React from "react";
import "styles/footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer is-dark">
      <div className="content has-text-centered">
        <span>&copy;Professions.io 2020</span>
        <Link to="/legal">Legal</Link>
        <Link to="/credits">Credits</Link>
      </div>
    </footer>
  );
}
