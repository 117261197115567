import { Recipe } from "types";

export const BlacksmithingRecipes: Recipe[] = [
  {
    result: {
      id: 22384,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 20520,
        amount: 1,
      },
      {
        id: 15417,
        amount: 1,
      },
      {
        id: 12753,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12420,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12613,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12611,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12612,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12614,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12610,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22383,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 13512,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12639,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22196,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22203,
        amount: 1,
      },
      {
        id: 22202,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12414,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 11186,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22385,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 13510,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12633,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18262,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 12365,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17014,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16989,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12426,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12427,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12417,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12632,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12782,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12662,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12792,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12625,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12631,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19051,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 12811,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19043,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12419,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12781,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11604,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12628,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12662,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12418,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12777,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12776,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12775,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11605,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12409,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 11185,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12410,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 11188,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12774,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12773,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11607,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12645,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16206,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15872,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11606,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12425,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12415,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12624,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11608,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12424,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12428,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7960,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 7081,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12416,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7947,
      amount: 1,
    },
    skill: 255,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12408,
      amount: 1,
    },
    skill: 255,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 11184,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7959,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7972,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12405,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 11188,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12406,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 11186,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12644,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 12365,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12404,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 12365,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12643,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 12365,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7961,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7081,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 3823,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7946,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7075,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7936,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7937,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7971,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7939,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 7971,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7934,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7944,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7935,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7954,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7075,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7933,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7932,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7969,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7945,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7971,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7930,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7931,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7929,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7943,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7928,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7938,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 5966,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7942,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7927,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7926,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7924,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7967,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7922,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7941,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7921,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7920,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9366,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7919,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7918,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3854,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 3829,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3847,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9060,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3856,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 3824,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7963,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11144,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15871,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7966,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7966,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 7912,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7964,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 7912,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7965,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 7912,
        amount: 1,
      },
      {
        id: 4306,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3845,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17704,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3829,
        amount: 1,
      },
      {
        id: 7070,
        amount: 1,
      },
      {
        id: 7069,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3837,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12260,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6041,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7917,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 5637,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6040,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3855,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3846,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7916,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 5637,
        amount: 1,
      },
      {
        id: 818,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12259,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3844,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
      {
        id: 4255,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3853,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7915,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 5637,
        amount: 1,
      },
      {
        id: 5635,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3841,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3850,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3852,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3843,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3836,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3835,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6043,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7914,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7913,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 5635,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3840,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3849,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3842,
      amount: 1,
    },
    skill: 155,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10423,
      amount: 1,
    },
    skill: 155,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3851,
      amount: 1,
    },
    skill: 155,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3485,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 5498,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6042,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11128,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15870,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 3486,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7071,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3484,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3492,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 3391,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2870,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 5500,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5541,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 5500,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7958,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3483,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7957,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3491,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3482,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2869,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7956,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3490,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 2459,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3481,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3486,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2838,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2871,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2838,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3241,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2838,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2850,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2868,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2849,
      amount: 1,
    },
    skill: 115,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2848,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5540,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3466,
        amount: 1,
      },
      {
        id: 5498,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3480,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
      {
        id: 3478,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2866,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2865,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3848,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
      {
        id: 818,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6731,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 818,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6338,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15869,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3487,
      amount: 1,
    },
    skill: 95,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 818,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6350,
      amount: 1,
    },
    skill: 95,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2854,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2864,
      amount: 1,
    },
    skill: 80,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3478,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 2836,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2857,
      amount: 1,
    },
    skill: 70,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3489,
      amount: 1,
    },
    skill: 70,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6214,
      amount: 1,
    },
    skill: 65,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2863,
      amount: 1,
    },
    skill: 65,
    reagents: [
      {
        id: 2836,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3240,
      amount: 1,
    },
    skill: 65,
    reagents: [
      {
        id: 2836,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3474,
      amount: 1,
    },
    skill: 60,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 818,
        amount: 1,
      },
      {
        id: 774,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3473,
      amount: 1,
    },
    skill: 45,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3472,
      amount: 1,
    },
    skill: 40,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3488,
      amount: 1,
    },
    skill: 35,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 774,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2851,
      amount: 1,
    },
    skill: 35,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3471,
      amount: 1,
    },
    skill: 35,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 774,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7955,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7166,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 3470,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2847,
      amount: 1,
    },
    skill: 25,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3470,
      amount: 1,
    },
    skill: 25,
    reagents: [
      {
        id: 2835,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2845,
      amount: 1,
    },
    skill: 20,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3469,
      amount: 1,
    },
    skill: 20,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2844,
      amount: 1,
    },
    skill: 15,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2852,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2853,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10421,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2862,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2835,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3239,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2835,
        amount: 1,
      },
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17193,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17203,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 11382,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12798,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12790,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
      {
        id: 12811,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12784,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19166,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 11382,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22194,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 22203,
        amount: 1,
      },
      {
        id: 22202,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 13512,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19167,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19168,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19690,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 19774,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19692,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 19774,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19691,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 19774,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20039,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17016,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 11382,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19164,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 11382,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19148,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17013,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17015,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 11382,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20550,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 20520,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20549,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 20520,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20551,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 20520,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 11754,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19693,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 19774,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19694,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 19774,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19695,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 19774,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19170,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12618,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12620,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12619,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16988,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12797,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19057,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 12811,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12796,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12783,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
      {
        id: 12644,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22197,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 22202,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19048,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12636,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 8168,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22671,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22669,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22670,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12422,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12429,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12641,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22764,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22762,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22763,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22198,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 22203,
        amount: 1,
      },
      {
        id: 22202,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22195,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 22202,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12640,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 8146,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12794,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19169,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22191,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 22203,
        amount: 1,
      },
      {
        id: 22202,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
    ],
  },
];
