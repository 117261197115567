import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { Profession } from "types";
import EditProfession from "components/EditProfession";

import "styles/profHeader.css";

interface ProfessionHeaderProps {
  profession: Profession;
  skillLevel: number;
  onSave: any;
  onDelete: any;
  owner: boolean;
}

export default function ProfessionHeader(props: ProfessionHeaderProps) {
  const [modal, toggleModal] = useState(false);
  const skillLevel = props.skillLevel;

  const m = modal ? (
    <EditProfession
      profId={props.profession.id}
      profSkill={skillLevel}
      secondary={props.profession.secondary}
      isActive={modal}
      toggle={toggleModal}
      onSave={props.onSave}
      onDelete={props.onDelete}
    />
  ) : null;

  const edit = props.owner ? <FontAwesomeIcon icon={faEdit} /> : null;

  return (
    <div className="profession-header">
      {m}
      <div>
        <h2 className="subtitle">{props.profession.name}</h2> <br />
        <span>{skillLevel}/300</span> - <span>({computeRank(skillLevel)})</span>
      </div>
      <span
        onClick={() => {
          toggleModal(true);
        }}
      >
        {edit}
      </span>
    </div>
  );
}

/** Translates into WoW Skill level name */
function computeRank(skillLevel: number): string {
  if (skillLevel === 0) {
    return "Unlearned";
  } else if (skillLevel > 0 && skillLevel <= 75) {
    return "Apprentice";
  } else if (skillLevel > 75 && skillLevel <= 150) {
    return "Journeyman";
  } else if (skillLevel > 150 && skillLevel <= 225) {
    return "Expert";
  } else {
    return "Artisan";
  }
}
