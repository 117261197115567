import React, { useEffect } from "react";
import { User } from "App";

interface LogoutProps {
  user: User | null;
}

export default function Logout(props: LogoutProps) {
  useEffect(() => {
    async function logout() {
      const resp = await fetch("https://api.professions.io/v0/logout", {
        method: "POST",
        credentials: "include",
      });

      if (resp.ok) {
        console.log("Logging out (for real)!");
        window.location.reload();
      } else {
        // TODO: report error here
        console.log("error :(");
        console.error(resp);
      }
    }

    if (props.user) {
      logout();
    }
  }, [props.user]);

  return <div>Logged out!</div>;
}
