import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Home from "pages/Home";
import Nav from "components/Nav";
import Footer from "components/Footer";
import CharacterList from "pages/CharacterList";
import CharacterView from "pages/CharacterView";
import LegalPage from "pages/legal/Legal";
import { LogIn } from "pages/LogIn";
import Logout from "pages/Logout";
import Account from "pages/Account";
import Credits from "pages/legal/Credits";

// TODO: move this
export interface User {
  email: string;
  id: number;
}

export default function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    async function loadUser() {
      const resp = await fetch("https://api.professions.io/v0/whoami", {
        credentials: "include",
      });
      if (resp.ok === false) {
        console.log("Token invalid; attempting to refresh");

        const resp2 = await fetch("https://api.professions.io/v0/refresh", {
          credentials: "include",
        });

        if (resp2.ok) {
          const json = await resp.json();
          setUser(json);
          return;
        } else {
          const t = await resp.text();
          console.log(t);
        }
      }

      const json = await resp.json();
      setUser(json);
    }

    loadUser();
  }, []);

  return (
    <Router>
      <Nav user={user} />
      <div className="app-body">
        <Switch>
          <Route path="/c/:id" children={<CharacterView user={user} />} />
          <Route path="/characters">
            <CharacterList user={user} />
          </Route>
          <Route path="/login">
            <LogIn
              user={user}
              title="Login"
              url="https://api.professions.io/v0/login"
            />
          </Route>
          <Route path="/register">
            <LogIn
              user={user}
              title="Register"
              url="https://api.professions.io/v0/register"
            />
          </Route>
          <Route path="/account">
            <Account user={user} />
          </Route>
          <Route path="/logout">
            <Logout user={user} />
          </Route>
          <Route path="/legal">
            <LegalPage />
          </Route>
          <Route path="/credits">
            <Credits />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}
