import { Recipe } from "types";

export const EnchantingRecipes: Recipe[] = [
  {
    result: {
      id: 20036,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20035,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 23802,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20011,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 16204,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20025,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25086,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25081,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25082,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25083,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25084,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 11754,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25078,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25074,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25079,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 12811,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25073,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25080,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 25072,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 20725,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 18512,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20034,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 12811,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22750,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 12811,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20032,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 23804,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 23803,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22749,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 13926,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20031,
      amount: 1,
      isSpell: true,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20748,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 18256,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20749,
      amount: 1,
      isSpell: false,
    },
    skill: 300,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 4625,
        amount: 1,
      },
      {
        id: 18256,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20030,
      amount: 1,
      isSpell: true,
    },
    skill: 295,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20023,
      amount: 1,
      isSpell: true,
    },
    skill: 295,
    reagents: [
      {
        id: 16203,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20010,
      amount: 1,
      isSpell: true,
    },
    skill: 295,
    reagents: [
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20013,
      amount: 1,
      isSpell: true,
    },
    skill: 295,
    reagents: [
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20033,
      amount: 1,
      isSpell: true,
    },
    skill: 295,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 27837,
      amount: 1,
      isSpell: true,
    },
    skill: 290,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 23801,
      amount: 1,
      isSpell: true,
    },
    skill: 290,
    reagents: [
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20028,
      amount: 1,
      isSpell: true,
    },
    skill: 290,
    reagents: [
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 14343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 23800,
      amount: 1,
      isSpell: true,
    },
    skill: 290,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 23799,
      amount: 1,
      isSpell: true,
    },
    skill: 290,
    reagents: [
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16207,
      amount: 1,
      isSpell: false,
    },
    skill: 290,
    reagents: [
      {
        id: 16206,
        amount: 1,
      },
      {
        id: 13926,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 14343,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20015,
      amount: 1,
      isSpell: true,
    },
    skill: 285,
    reagents: [
      {
        id: 16204,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20029,
      amount: 1,
      isSpell: true,
    },
    skill: 285,
    reagents: [
      {
        id: 14343,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 13467,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20016,
      amount: 1,
      isSpell: true,
    },
    skill: 280,
    reagents: [
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20024,
      amount: 1,
      isSpell: true,
    },
    skill: 275,
    reagents: [
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 16202,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20026,
      amount: 1,
      isSpell: true,
    },
    skill: 275,
    reagents: [
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 14343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20750,
      amount: 1,
      isSpell: false,
    },
    skill: 275,
    reagents: [
      {
        id: 16204,
        amount: 1,
      },
      {
        id: 4625,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20009,
      amount: 1,
      isSpell: true,
    },
    skill: 270,
    reagents: [
      {
        id: 16202,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20012,
      amount: 1,
      isSpell: true,
    },
    skill: 270,
    reagents: [
      {
        id: 16202,
        amount: 1,
      },
      {
        id: 16204,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20014,
      amount: 1,
      isSpell: true,
    },
    skill: 265,
    reagents: [
      {
        id: 16202,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 7075,
        amount: 1,
      },
      {
        id: 7079,
        amount: 1,
      },
      {
        id: 7081,
        amount: 1,
      },
      {
        id: 7972,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20017,
      amount: 1,
      isSpell: true,
    },
    skill: 265,
    reagents: [
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13898,
      amount: 1,
      isSpell: true,
    },
    skill: 265,
    reagents: [
      {
        id: 11177,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11811,
      amount: 1,
      isSpell: false,
    },
    skill: 265,
    reagents: [
      {
        id: 11382,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 14343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20020,
      amount: 1,
      isSpell: true,
    },
    skill: 260,
    reagents: [
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20008,
      amount: 1,
      isSpell: true,
    },
    skill: 255,
    reagents: [
      {
        id: 16202,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13948,
      amount: 1,
      isSpell: true,
    },
    skill: 250,
    reagents: [
      {
        id: 11178,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13947,
      amount: 1,
      isSpell: true,
    },
    skill: 250,
    reagents: [
      {
        id: 11178,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20747,
      amount: 1,
      isSpell: false,
    },
    skill: 250,
    reagents: [
      {
        id: 11176,
        amount: 1,
      },
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12810,
      amount: 1,
      isSpell: false,
    },
    skill: 250,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 16202,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12655,
      amount: 1,
      isSpell: false,
    },
    skill: 250,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13945,
      amount: 1,
      isSpell: true,
    },
    skill: 245,
    reagents: [
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13941,
      amount: 1,
      isSpell: true,
    },
    skill: 245,
    reagents: [
      {
        id: 11178,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
      {
        id: 11175,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13943,
      amount: 1,
      isSpell: true,
    },
    skill: 245,
    reagents: [
      {
        id: 11178,
        amount: 1,
      },
      {
        id: 11175,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13937,
      amount: 1,
      isSpell: true,
    },
    skill: 240,
    reagents: [
      {
        id: 11178,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13939,
      amount: 1,
      isSpell: true,
    },
    skill: 240,
    reagents: [
      {
        id: 11176,
        amount: 1,
      },
      {
        id: 11175,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13935,
      amount: 1,
      isSpell: true,
    },
    skill: 235,
    reagents: [
      {
        id: 11175,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13931,
      amount: 1,
      isSpell: true,
    },
    skill: 235,
    reagents: [
      {
        id: 11175,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13933,
      amount: 1,
      isSpell: true,
    },
    skill: 235,
    reagents: [
      {
        id: 11178,
        amount: 1,
      },
      {
        id: 3829,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13917,
      amount: 1,
      isSpell: true,
    },
    skill: 230,
    reagents: [
      {
        id: 11175,
        amount: 1,
      },
      {
        id: 11174,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13905,
      amount: 1,
      isSpell: true,
    },
    skill: 230,
    reagents: [
      {
        id: 11175,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13915,
      amount: 1,
      isSpell: true,
    },
    skill: 230,
    reagents: [
      {
        id: 11177,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
      {
        id: 9224,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13890,
      amount: 1,
      isSpell: true,
    },
    skill: 225,
    reagents: [
      {
        id: 11177,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 11174,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13882,
      amount: 1,
      isSpell: true,
    },
    skill: 225,
    reagents: [
      {
        id: 11174,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13868,
      amount: 1,
      isSpell: true,
    },
    skill: 225,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 8838,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13887,
      amount: 1,
      isSpell: true,
    },
    skill: 225,
    reagents: [
      {
        id: 11174,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13846,
      amount: 1,
      isSpell: true,
    },
    skill: 220,
    reagents: [
      {
        id: 11174,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13858,
      amount: 1,
      isSpell: true,
    },
    skill: 220,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13836,
      amount: 1,
      isSpell: true,
    },
    skill: 215,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13841,
      amount: 1,
      isSpell: true,
    },
    skill: 215,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13822,
      amount: 1,
      isSpell: true,
    },
    skill: 210,
    reagents: [
      {
        id: 11174,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13815,
      amount: 1,
      isSpell: true,
    },
    skill: 210,
    reagents: [
      {
        id: 11174,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13817,
      amount: 1,
      isSpell: true,
    },
    skill: 210,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13746,
      amount: 1,
      isSpell: true,
    },
    skill: 205,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13794,
      amount: 1,
      isSpell: true,
    },
    skill: 205,
    reagents: [
      {
        id: 11174,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13695,
      amount: 1,
      isSpell: true,
    },
    skill: 200,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 11139,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13700,
      amount: 1,
      isSpell: true,
    },
    skill: 200,
    reagents: [
      {
        id: 11135,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 11139,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13698,
      amount: 1,
      isSpell: true,
    },
    skill: 200,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 7392,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11145,
      amount: 1,
      isSpell: false,
    },
    skill: 200,
    reagents: [
      {
        id: 11144,
        amount: 1,
      },
      {
        id: 7971,
        amount: 1,
      },
      {
        id: 11135,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20746,
      amount: 1,
      isSpell: false,
    },
    skill: 200,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 17035,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13689,
      amount: 1,
      isSpell: true,
    },
    skill: 195,
    reagents: [
      {
        id: 11135,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 11139,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13693,
      amount: 1,
      isSpell: true,
    },
    skill: 195,
    reagents: [
      {
        id: 11135,
        amount: 1,
      },
      {
        id: 11139,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13687,
      amount: 1,
      isSpell: true,
    },
    skill: 190,
    reagents: [
      {
        id: 11135,
        amount: 1,
      },
      {
        id: 11134,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21931,
      amount: 1,
      isSpell: true,
    },
    skill: 190,
    reagents: [
      {
        id: 11135,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 11139,
        amount: 1,
      },
      {
        id: 3819,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13663,
      amount: 1,
      isSpell: true,
    },
    skill: 185,
    reagents: [
      {
        id: 11135,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13661,
      amount: 1,
      isSpell: true,
    },
    skill: 180,
    reagents: [
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13659,
      amount: 1,
      isSpell: true,
    },
    skill: 180,
    reagents: [
      {
        id: 11135,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13657,
      amount: 1,
      isSpell: true,
    },
    skill: 175,
    reagents: [
      {
        id: 11134,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13653,
      amount: 1,
      isSpell: true,
    },
    skill: 175,
    reagents: [
      {
        id: 11134,
        amount: 1,
      },
      {
        id: 5637,
        amount: 1,
      },
      {
        id: 11138,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13655,
      amount: 1,
      isSpell: true,
    },
    skill: 175,
    reagents: [
      {
        id: 11134,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 11138,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11290,
      amount: 1,
      isSpell: false,
    },
    skill: 175,
    reagents: [
      {
        id: 11291,
        amount: 1,
      },
      {
        id: 11135,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13644,
      amount: 1,
      isSpell: true,
    },
    skill: 170,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13646,
      amount: 1,
      isSpell: true,
    },
    skill: 170,
    reagents: [
      {
        id: 11134,
        amount: 1,
      },
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13648,
      amount: 1,
      isSpell: true,
    },
    skill: 170,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13642,
      amount: 1,
      isSpell: true,
    },
    skill: 165,
    reagents: [
      {
        id: 11134,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13637,
      amount: 1,
      isSpell: true,
    },
    skill: 160,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
      {
        id: 11134,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13640,
      amount: 1,
      isSpell: true,
    },
    skill: 160,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13635,
      amount: 1,
      isSpell: true,
    },
    skill: 155,
    reagents: [
      {
        id: 11138,
        amount: 1,
      },
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13631,
      amount: 1,
      isSpell: true,
    },
    skill: 155,
    reagents: [
      {
        id: 11134,
        amount: 1,
      },
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11289,
      amount: 1,
      isSpell: false,
    },
    skill: 155,
    reagents: [
      {
        id: 11291,
        amount: 1,
      },
      {
        id: 11134,
        amount: 1,
      },
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13622,
      amount: 1,
      isSpell: true,
    },
    skill: 150,
    reagents: [
      {
        id: 11082,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13626,
      amount: 1,
      isSpell: true,
    },
    skill: 150,
    reagents: [
      {
        id: 11082,
        amount: 1,
      },
      {
        id: 11083,
        amount: 1,
      },
      {
        id: 11084,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11130,
      amount: 1,
      isSpell: false,
    },
    skill: 150,
    reagents: [
      {
        id: 11128,
        amount: 1,
      },
      {
        id: 5500,
        amount: 1,
      },
      {
        id: 11082,
        amount: 1,
      },
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20745,
      amount: 1,
      isSpell: false,
    },
    skill: 150,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
      {
        id: 17034,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13529,
      amount: 1,
      isSpell: true,
    },
    skill: 145,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
      {
        id: 11084,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13607,
      amount: 1,
      isSpell: true,
    },
    skill: 145,
    reagents: [
      {
        id: 11082,
        amount: 1,
      },
      {
        id: 10998,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13620,
      amount: 1,
      isSpell: true,
    },
    skill: 145,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
      {
        id: 6370,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13617,
      amount: 1,
      isSpell: true,
    },
    skill: 145,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
      {
        id: 3356,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13612,
      amount: 1,
      isSpell: true,
    },
    skill: 145,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
      {
        id: 2772,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13536,
      amount: 1,
      isSpell: true,
    },
    skill: 140,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13538,
      amount: 1,
      isSpell: true,
    },
    skill: 140,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 11082,
        amount: 1,
      },
      {
        id: 11084,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13503,
      amount: 1,
      isSpell: true,
    },
    skill: 140,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
      {
        id: 11084,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13522,
      amount: 1,
      isSpell: true,
    },
    skill: 135,
    reagents: [
      {
        id: 11082,
        amount: 1,
      },
      {
        id: 6048,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13501,
      amount: 1,
      isSpell: true,
    },
    skill: 130,
    reagents: [
      {
        id: 11083,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13485,
      amount: 1,
      isSpell: true,
    },
    skill: 130,
    reagents: [
      {
        id: 10998,
        amount: 1,
      },
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7867,
      amount: 1,
      isSpell: true,
    },
    skill: 125,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10998,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7863,
      amount: 1,
      isSpell: true,
    },
    skill: 125,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7861,
      amount: 1,
      isSpell: true,
    },
    skill: 125,
    reagents: [
      {
        id: 6371,
        amount: 1,
      },
      {
        id: 10998,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7859,
      amount: 1,
      isSpell: true,
    },
    skill: 120,
    reagents: [
      {
        id: 10998,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7857,
      amount: 1,
      isSpell: true,
    },
    skill: 120,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10998,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13421,
      amount: 1,
      isSpell: true,
    },
    skill: 115,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10978,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13464,
      amount: 1,
      isSpell: true,
    },
    skill: 115,
    reagents: [
      {
        id: 10998,
        amount: 1,
      },
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10978,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13380,
      amount: 1,
      isSpell: true,
    },
    skill: 110,
    reagents: [
      {
        id: 10998,
        amount: 1,
      },
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13419,
      amount: 1,
      isSpell: true,
    },
    skill: 110,
    reagents: [
      {
        id: 10998,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13378,
      amount: 1,
      isSpell: true,
    },
    skill: 105,
    reagents: [
      {
        id: 10998,
        amount: 1,
      },
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7793,
      amount: 1,
      isSpell: true,
    },
    skill: 100,
    reagents: [
      {
        id: 10939,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7745,
      amount: 1,
      isSpell: true,
    },
    skill: 100,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10978,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6339,
      amount: 1,
      isSpell: false,
    },
    skill: 100,
    reagents: [
      {
        id: 6338,
        amount: 1,
      },
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10939,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7786,
      amount: 1,
      isSpell: true,
    },
    skill: 90,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10939,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7788,
      amount: 1,
      isSpell: true,
    },
    skill: 90,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10939,
        amount: 1,
      },
      {
        id: 10978,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7779,
      amount: 1,
      isSpell: true,
    },
    skill: 80,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10939,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7782,
      amount: 1,
      isSpell: true,
    },
    skill: 80,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7776,
      amount: 1,
      isSpell: true,
    },
    skill: 80,
    reagents: [
      {
        id: 10939,
        amount: 1,
      },
      {
        id: 10938,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7428,
      amount: 1,
      isSpell: true,
    },
    skill: 80,
    reagents: [
      {
        id: 10938,
        amount: 1,
      },
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7771,
      amount: 1,
      isSpell: true,
    },
    skill: 70,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10939,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11288,
      amount: 1,
      isSpell: false,
    },
    skill: 70,
    reagents: [
      {
        id: 4470,
        amount: 1,
      },
      {
        id: 10939,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7418,
      amount: 1,
      isSpell: true,
    },
    skill: 70,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7766,
      amount: 1,
      isSpell: true,
    },
    skill: 60,
    reagents: [
      {
        id: 10938,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7748,
      amount: 1,
      isSpell: true,
    },
    skill: 60,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10938,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7457,
      amount: 1,
      isSpell: true,
    },
    skill: 50,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7454,
      amount: 1,
      isSpell: true,
    },
    skill: 45,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10938,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20744,
      amount: 1,
      isSpell: false,
    },
    skill: 45,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 17034,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7426,
      amount: 1,
      isSpell: true,
    },
    skill: 40,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10938,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7443,
      amount: 1,
      isSpell: true,
    },
    skill: 20,
    reagents: [
      {
        id: 10938,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7420,
      amount: 1,
      isSpell: true,
    },
    skill: 15,
    reagents: [
      {
        id: 10940,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11287,
      amount: 1,
      isSpell: false,
    },
    skill: 10,
    reagents: [
      {
        id: 4470,
        amount: 1,
      },
      {
        id: 10938,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6218,
      amount: 1,
      isSpell: false,
    },
    skill: 1,
    reagents: [
      {
        id: 6217,
        amount: 1,
      },
      {
        id: 10940,
        amount: 1,
      },
      {
        id: 10938,
        amount: 1,
      },
    ],
  },
];
