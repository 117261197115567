import React, { useState } from "react";
import classNames from "classnames";

interface EditCharacterProps {
  isActive: boolean;
  setOpen: any; // TODO: actual type here
}

export default function EditCharacter(props: EditCharacterProps) {
  const [name, setName] = useState("");
  const [realm, setRealm] = useState("");
  const [race, setRace] = useState("orc");
  const [gender, setGender] = useState("male");

  const [error, setError] = useState("");

  async function create(onErr: any) {
    const resp = await fetch("https://api.professions.io/v0/character", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        realm,
        race,
        gender,
      }),
    });
    if (resp.ok) {
      props.setOpen(false);
      window.location.reload();
    } else {
      const text = await resp.text();
      onErr(text);
    }
  }

  const cs = classNames("modal", {
    "is-active": props.isActive,
  });

  let errElem;
  if (error) {
    errElem = (
      <article className="message is-danger">
        <div className="message-body">{error}</div>
      </article>
    );
  }

  return (
    <div className={cs}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">New Character</p>
        </header>
        <section className="modal-card-body">
          {errElem}
          <div>
            <label htmlFor="name-input">Name:</label>
            <input
              id="name-input"
              type="text"
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="realm-input">Realm:</label>
            <input
              id="realm-input"
              type="text"
              required
              value={realm}
              onChange={(e) => {
                setRealm(e.target.value);
              }}
            />
          </div>
          <select
            value={race}
            onChange={(e) => {
              setRace(e.target.value);
            }}
            required
          >
            <option value="orc">Orc</option>
            <option value="troll">Troll</option>
            <option value="tauren">Tauren</option>
            <option value="forsaken">Forsaken</option>
            <option value="human">Human</option>
            <option value="dwarf">Dwarf</option>
            <option value="gnome">Gnome</option>
            <option value="ne">Night Elf</option>
          </select>
          <select
            value={gender}
            onChange={(e) => {
              setGender(e.target.value);
            }}
            required
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            onClick={() => {
              create((text: any) => {
                setError(text);
              });
            }}
          >
            Create
          </button>
          <button
            className="button"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}
