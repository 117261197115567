import { Recipe } from "types";

export const EngineeringRecipes: Recipe[] = [
  {
    result: {
      id: 16040,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 16006,
        amount: 1,
      },
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16022,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 10576,
        amount: 1,
      },
      {
        id: 16006,
        amount: 1,
      },
      {
        id: 12655,
        amount: 1,
      },
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18283,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 16006,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 16000,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19999,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 19774,
        amount: 1,
      },
      {
        id: 16006,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19998,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 19774,
        amount: 1,
      },
      {
        id: 16006,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18282,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 16006,
        amount: 1,
      },
      {
        id: 16000,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18232,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7191,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16007,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 16000,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18168,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 16006,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18639,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18638,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16008,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 10500,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16009,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 16006,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16005,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 15992,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16006,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15997,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 15992,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18984,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 10586,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18986,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 7075,
        amount: 1,
      },
      {
        id: 7079,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 9060,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16004,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 16000,
        amount: 1,
      },
      {
        id: 11371,
        amount: 1,
      },
      {
        id: 10546,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 12799,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21570,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 9060,
        amount: 1,
      },
      {
        id: 9061,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 10561,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16023,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 16000,
        amount: 1,
      },
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22728,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16000,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18637,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 16000,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18594,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 15992,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 159,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21714,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 15992,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21716,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 15992,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21718,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 15992,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15999,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 10502,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18587,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 7191,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15996,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18645,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 7191,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18634,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 12361,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 3829,
        amount: 1,
      },
      {
        id: 13467,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15993,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 15992,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15995,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 10546,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15994,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18631,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 7069,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18660,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21277,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 15994,
        amount: 1,
      },
      {
        id: 7079,
        amount: 1,
      },
      {
        id: 18631,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10576,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 9060,
        amount: 1,
      },
      {
        id: 9061,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15846,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18641,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 15992,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19026,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 15992,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
      {
        id: 8150,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15992,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 12365,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10588,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 10543,
        amount: 1,
      },
      {
        id: 9061,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10504,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 10286,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10513,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10645,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 7972,
        amount: 1,
      },
      {
        id: 9060,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10727,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 9061,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10548,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10726,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10562,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10586,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 9061,
        amount: 1,
      },
      {
        id: 10507,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10506,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 818,
        amount: 1,
      },
      {
        id: 774,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10725,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 9060,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10503,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10587,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 4407,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21569,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 9060,
        amount: 1,
      },
      {
        id: 9061,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 10561,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10724,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 10026,
        amount: 1,
      },
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 4389,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7189,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 10026,
        amount: 1,
      },
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 9061,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10518,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 10285,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10502,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21571,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21574,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21576,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10577,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10501,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 10592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10510,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 4400,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10721,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 7387,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10561,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10514,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 10561,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10546,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 7909,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10545,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 10500,
        amount: 1,
      },
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
      {
        id: 8151,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10720,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 10285,
        amount: 1,
      },
      {
        id: 4337,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10512,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10500,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4385,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10716,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 8151,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10543,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10542,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10508,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 10559,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
      {
        id: 4400,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10646,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4338,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 10560,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10644,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 10648,
        amount: 1,
      },
      {
        id: 10647,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10713,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 10648,
        amount: 1,
      },
      {
        id: 10647,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11826,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 7075,
        amount: 1,
      },
      {
        id: 4389,
        amount: 1,
      },
      {
        id: 7191,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11825,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4394,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 7191,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10577,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 10577,
        amount: 1,
      },
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4397,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4389,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 7191,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4396,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4382,
        amount: 1,
      },
      {
        id: 4387,
        amount: 1,
      },
      {
        id: 4389,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 7191,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4398,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 159,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 11590,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10560,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
      {
        id: 10505,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18588,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4395,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 4389,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10559,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4394,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4404,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17716,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
      {
        id: 4389,
        amount: 1,
      },
      {
        id: 17202,
        amount: 1,
      },
      {
        id: 3829,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4393,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4392,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4387,
        amount: 1,
      },
      {
        id: 4382,
        amount: 1,
      },
      {
        id: 4389,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4852,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4611,
        amount: 1,
      },
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4306,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4407,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 4371,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },

  {
    result: {
      id: 10499,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4391,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4387,
        amount: 1,
      },
      {
        id: 4382,
        amount: 1,
      },
      {
        id: 4389,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10498,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 3859,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4390,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4306,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21589,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21590,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21592,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10505,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 7912,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10507,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 10505,
        amount: 1,
      },
      {
        id: 4306,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4389,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 10558,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4403,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 4371,
        amount: 1,
      },
      {
        id: 4387,
        amount: 1,
      },
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7148,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 814,
        amount: 1,
      },
      {
        id: 4306,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
      {
        id: 7191,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4388,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 4306,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 4371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4387,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4386,
      amount: 1,
    },
    skill: 155,
    reagents: [
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 3829,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4384,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4382,
        amount: 1,
      },
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4385,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
      {
        id: 4368,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10558,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 3577,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9312,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9313,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9318,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6533,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 6530,
        amount: 1,
      },
      {
        id: 4364,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4383,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 4371,
        amount: 1,
      },
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 4400,
        amount: 1,
      },
      {
        id: 1705,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4382,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4381,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 4371,
        amount: 1,
      },
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4380,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 4404,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5507,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 4371,
        amount: 1,
      },
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 4363,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4379,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 4371,
        amount: 1,
      },
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 4400,
        amount: 1,
      },
      {
        id: 2842,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7506,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 814,
        amount: 1,
      },
      {
        id: 818,
        amount: 1,
      },
      {
        id: 774,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4376,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4375,
        amount: 1,
      },
      {
        id: 4402,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4375,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21558,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21559,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21557,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8069,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 2841,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4377,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2838,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4378,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4377,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4372,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 4371,
        amount: 1,
      },
      {
        id: 4359,
        amount: 1,
      },
      {
        id: 4400,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4373,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4374,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 4404,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4406,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 4371,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4369,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 4361,
        amount: 1,
      },
      {
        id: 4359,
        amount: 1,
      },
      {
        id: 4399,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4371,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4370,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 4404,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4368,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 818,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4367,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 4363,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 159,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6714,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6712,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 4359,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4404,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 2842,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4366,
      amount: 1,
    },
    skill: 85,
    reagents: [
      {
        id: 4363,
        amount: 1,
      },
      {
        id: 4359,
        amount: 1,
      },
      {
        id: 2841,
        amount: 1,
      },
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4401,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 4363,
        amount: 1,
      },
      {
        id: 4359,
        amount: 1,
      },
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 774,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4365,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4364,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 2836,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8068,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 4364,
        amount: 1,
      },
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4363,
      amount: 1,
    },
    skill: 65,
    reagents: [
      {
        id: 4359,
        amount: 1,
      },
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4405,
      amount: 1,
    },
    skill: 60,
    reagents: [
      {
        id: 4361,
        amount: 1,
      },
      {
        id: 774,
        amount: 1,
      },
      {
        id: 4359,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4361,
      amount: 1,
    },
    skill: 50,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 2880,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4362,
      amount: 1,
    },
    skill: 50,
    reagents: [
      {
        id: 4361,
        amount: 1,
      },
      {
        id: 4359,
        amount: 1,
      },
      {
        id: 4399,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6219,
      amount: 1,
    },
    skill: 50,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4360,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
      {
        id: 4359,
        amount: 1,
      },
      {
        id: 4357,
        amount: 1,
      },
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4359,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8067,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 4357,
        amount: 1,
      },
      {
        id: 2840,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4358,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 4357,
        amount: 1,
      },
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4357,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2835,
        amount: 1,
      },
    ],
  },
];
