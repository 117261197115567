import { Recipe } from "types";

export const AlchemyRecipes: Recipe[] = [
  {
    result: {
      id: 19931,
      amount: 1,
    },
    skill: 315,
    reagents: [
      {
        id: 12938,
        amount: 1,
      },
      {
        id: 19943,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13503,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 9262,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13513,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 13467,
        amount: 1,
      },
      {
        id: 13465,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13511,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 13467,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13506,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 13423,
        amount: 1,
      },
      {
        id: 13465,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13512,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 13465,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13510,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8846,
        amount: 1,
      },
      {
        id: 13423,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18253,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 10286,
        amount: 1,
      },
      {
        id: 13464,
        amount: 1,
      },
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 18256,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7068,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 7077,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13444,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 13467,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13461,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 11176,
        amount: 1,
      },
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13457,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 7068,
        amount: 1,
      },
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13456,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 7070,
        amount: 1,
      },
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13458,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13459,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 3824,
        amount: 1,
      },
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20004,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8846,
        amount: 1,
      },
      {
        id: 13466,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13454,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 13465,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20008,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 13467,
        amount: 1,
      },
      {
        id: 13465,
        amount: 1,
      },
      {
        id: 10286,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13462,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 13467,
        amount: 1,
      },
      {
        id: 13466,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13452,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 13465,
        amount: 1,
      },
      {
        id: 13466,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13455,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 13423,
        amount: 1,
      },
      {
        id: 10620,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13453,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 8846,
        amount: 1,
      },
      {
        id: 13466,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20002,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 13464,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20007,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 13466,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13446,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 13464,
        amount: 1,
      },
      {
        id: 13465,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7078,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 7082,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12360,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12359,
        amount: 1,
      },
      {
        id: 12363,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12803,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 7076,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7080,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 7076,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7076,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 7078,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7076,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12803,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7080,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 12808,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7082,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 7080,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12808,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 7080,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13447,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 13463,
        amount: 1,
      },
      {
        id: 13466,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13445,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 13423,
        amount: 1,
      },
      {
        id: 8838,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13443,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 8838,
        amount: 1,
      },
      {
        id: 8839,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13442,
      amount: 1,
    },
    skill: 255,
    reagents: [
      {
        id: 8846,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3387,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 8839,
        amount: 1,
      },
      {
        id: 8845,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9224,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 8846,
        amount: 1,
      },
      {
        id: 8845,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9233,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 8846,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21546,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 6371,
        amount: 1,
      },
      {
        id: 4625,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9264,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 8845,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13423,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 13422,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9206,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 8838,
        amount: 1,
      },
      {
        id: 8846,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9210,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 8845,
        amount: 1,
      },
      {
        id: 4342,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9197,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9187,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 8838,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9088,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 8836,
        amount: 1,
      },
      {
        id: 8839,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9155,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 8839,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9179,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 8839,
        amount: 1,
      },
      {
        id: 3358,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9172,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 8845,
        amount: 1,
      },
      {
        id: 8838,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 12190,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9154,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 8836,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9149,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
      {
        id: 9262,
        amount: 1,
      },
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 4625,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3577,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 3575,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6037,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 3860,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9144,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18294,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 7972,
        amount: 1,
      },
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4623,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 3858,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3928,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 8838,
        amount: 1,
      },
      {
        id: 3358,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9030,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9061,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 4625,
        amount: 1,
      },
      {
        id: 9260,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9036,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 3358,
        amount: 1,
      },
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6149,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 3358,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8956,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4625,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 8925,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10592,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 3818,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3829,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 3358,
        amount: 1,
      },
      {
        id: 3819,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3828,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 3358,
        amount: 1,
      },
      {
        id: 3818,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8951,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 3355,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17708,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3819,
        amount: 1,
      },
      {
        id: 3358,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6052,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3357,
        amount: 1,
      },
      {
        id: 3820,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6050,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 3819,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8949,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 3820,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3826,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 3357,
        amount: 1,
      },
      {
        id: 2453,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3825,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 3355,
        amount: 1,
      },
      {
        id: 3821,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5633,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 5637,
        amount: 1,
      },
      {
        id: 3356,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6049,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 4402,
        amount: 1,
      },
      {
        id: 6371,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3824,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 3818,
        amount: 1,
      },
      {
        id: 3369,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3823,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 3818,
        amount: 1,
      },
      {
        id: 3355,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3827,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 3820,
        amount: 1,
      },
      {
        id: 3356,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 1710,
      amount: 1,
    },
    skill: 155,
    reagents: [
      {
        id: 3357,
        amount: 1,
      },
      {
        id: 3356,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3391,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 2449,
        amount: 1,
      },
      {
        id: 3356,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5634,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 6370,
        amount: 1,
      },
      {
        id: 3820,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6373,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 6371,
        amount: 1,
      },
      {
        id: 3356,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3390,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 3355,
        amount: 1,
      },
      {
        id: 2452,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6048,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 3369,
        amount: 1,
      },
      {
        id: 3356,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3389,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 3355,
        amount: 1,
      },
      {
        id: 3820,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6371,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 6359,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3388,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2453,
        amount: 1,
      },
      {
        id: 2450,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3386,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 1288,
        amount: 1,
      },
      {
        id: 2453,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3385,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 785,
        amount: 1,
      },
      {
        id: 3820,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 929,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2453,
        amount: 1,
      },
      {
        id: 2450,
        amount: 1,
      },
      {
        id: 3372,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3384,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 785,
        amount: 1,
      },
      {
        id: 3355,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6051,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2453,
        amount: 1,
      },
      {
        id: 2452,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6372,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2452,
        amount: 1,
      },
      {
        id: 6370,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6662,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 6522,
        amount: 1,
      },
      {
        id: 2449,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5996,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 3820,
        amount: 1,
      },
      {
        id: 6370,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3383,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 785,
        amount: 1,
      },
      {
        id: 2450,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6370,
      amount: 1,
    },
    skill: 80,
    reagents: [
      {
        id: 6358,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5631,
      amount: 1,
    },
    skill: 60,
    reagents: [
      {
        id: 5635,
        amount: 1,
      },
      {
        id: 2450,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2459,
      amount: 1,
    },
    skill: 60,
    reagents: [
      {
        id: 2452,
        amount: 1,
      },
      {
        id: 2450,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 858,
      amount: 1,
    },
    skill: 55,
    reagents: [
      {
        id: 118,
        amount: 1,
      },
      {
        id: 2450,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4596,
      amount: 1,
    },
    skill: 50,
    reagents: [
      {
        id: 3164,
        amount: 1,
      },
      {
        id: 2447,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2457,
      amount: 1,
    },
    skill: 50,
    reagents: [
      {
        id: 2452,
        amount: 1,
      },
      {
        id: 765,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2458,
      amount: 1,
    },
    skill: 50,
    reagents: [
      {
        id: 2449,
        amount: 1,
      },
      {
        id: 2447,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2456,
      amount: 1,
    },
    skill: 40,
    reagents: [
      {
        id: 785,
        amount: 1,
      },
      {
        id: 2447,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2455,
      amount: 1,
    },
    skill: 25,
    reagents: [
      {
        id: 785,
        amount: 1,
      },
      {
        id: 765,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3382,
      amount: 1,
    },
    skill: 15,
    reagents: [
      {
        id: 2447,
        amount: 1,
      },
      {
        id: 2449,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2454,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2449,
        amount: 1,
      },
      {
        id: 765,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5997,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 765,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 118,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2447,
        amount: 1,
      },
      {
        id: 765,
        amount: 1,
      },
      {
        id: 3371,
        amount: 1,
      },
    ],
  },
];
