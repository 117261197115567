import React from "react";

export default function Credits() {
  return (
    <section className="section" id="credits">
      <div className="container">
        <h1 className="title">Credits</h1>
        <p>
          World of Warcraft, Warcraft and Blizzard Entertainment are trademarks
          or registered trademarks of Blizzard Entertainment, Inc. in the U.S.
          and/or other countries.
        </p>
        <br />
        <p>
          Icons extracted and packaged by <b>teebling</b> on{" "}
          <a href="https://barrens.chat/viewtopic.php?f=5&t=901">
            barrens.chat
          </a>
        </p>
        <br />
        <p>
          Item and Spell data from{" "}
          <a href="https://github.com/TyrsDev/WoW-Classic-Database">
            TyrsDev on Github
          </a>
        </p>
      </div>
    </section>
  );
}
