import React from "react";
import { User } from "App";

interface NavProps {
  user: User | null;
}

export default function Nav(props: NavProps) {
  const account = props.user ? (
    <div className="buttons">
      <a className="button is-link" href="/account">
        <strong>Account</strong>
      </a>
      <a className="button is-light" href="/logout">
        Log out
      </a>
    </div>
  ) : (
    <div className="buttons">
      <a className="button is-link" href="/register">
        <strong>Sign up</strong>
      </a>
      <a className="button is-light" href="/login">
        Log in
      </a>
    </div>
  );

  return (
    <nav
      className="navbar is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          professions.io
        </a>
      </div>

      <div id="navbarBasicExample" className="navbar-menu is-active">
        <div className="navbar-start">
          <a className="navbar-item" href="/characters">
            Characters
          </a>
        </div>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">{account}</div>
      </div>
    </nav>
  );
}
