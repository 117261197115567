import React from "react";
import "styles/home.css";

export default function Home() {
  return (
    <div id="home">
      <section className="hero is-primary is-medium" id="hero">
        <div id="hero-bg" />
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Track and share your WoW professions!</h1>
            <h2 className="subtitle">Classic WoW Profession Tracker</h2>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" id="home-body">
          <p>We make it easy to track and share what recipes you have.</p>
          <p>
            Just <a href="/register">create an account</a> and get tracking! Or,
            you can{" "}
            <a href="https://www.professions.io/c/RhfQ4xHpjm8n2ouLeFJ7tn">
              preview an exiting character
            </a>
            .
          </p>
        </div>
      </section>
    </div>
  );
}
