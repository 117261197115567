import { Recipe } from "types";

export const CookingRecipes: Recipe[] = [
  {
    result: {
      id: 2679,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 2672,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2681,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 769,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2680,
      amount: 1
    },
    skill: 10,
    reagents: [
      {
        id: 2672,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2684,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 2673,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 724,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 723,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 733,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 729,
        amount: 1
      },
      {
        id: 730,
        amount: 1
      },
      {
        id: 731,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2683,
      amount: 1
    },
    skill: 75,
    reagents: [
      {
        id: 2674,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2682,
      amount: 1
    },
    skill: 85,
    reagents: [
      {
        id: 2675,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2687,
      amount: 1
    },
    skill: 80,
    reagents: [
      {
        id: 2677,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 1082,
      amount: 1
    },
    skill: 100,
    reagents: [
      {
        id: 1081,
        amount: 1
      },
      {
        id: 1080,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2685,
      amount: 1
    },
    skill: 110,
    reagents: [
      {
        id: 2677,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 1017,
      amount: 1
    },
    skill: 100,
    reagents: [
      {
        id: 1015,
        amount: 1
      },
      {
        id: 2665,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2888,
      amount: 1
    },
    skill: 10,
    reagents: [
      {
        id: 2886,
        amount: 1
      },
      {
        id: 2894,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3662,
      amount: 1
    },
    skill: 80,
    reagents: [
      {
        id: 2924,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3220,
      amount: 1
    },
    skill: 60,
    reagents: [
      {
        id: 3173,
        amount: 1
      },
      {
        id: 3172,
        amount: 1
      },
      {
        id: 3174,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3663,
      amount: 1
    },
    skill: 90,
    reagents: [
      {
        id: 1468,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3664,
      amount: 1
    },
    skill: 120,
    reagents: [
      {
        id: 3667,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3665,
      amount: 1
    },
    skill: 130,
    reagents: [
      {
        id: 3685,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3666,
      amount: 1
    },
    skill: 110,
    reagents: [
      {
        id: 2251,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3726,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 3730,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3727,
      amount: 1
    },
    skill: 125,
    reagents: [
      {
        id: 3731,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3728,
      amount: 1
    },
    skill: 150,
    reagents: [
      {
        id: 3731,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3729,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 3712,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 4457,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 3404,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5472,
      amount: 1
    },
    skill: 10,
    reagents: [
      {
        id: 5465,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5473,
      amount: 1
    },
    skill: 20,
    reagents: [
      {
        id: 5466,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5474,
      amount: 1
    },
    skill: 35,
    reagents: [
      {
        id: 5467,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5476,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 5468,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5477,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 5469,
        amount: 1
      },
      {
        id: 4536,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5478,
      amount: 1
    },
    skill: 90,
    reagents: [
      {
        id: 5051,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5479,
      amount: 1
    },
    skill: 100,
    reagents: [
      {
        id: 5470,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5480,
      amount: 1
    },
    skill: 110,
    reagents: [
      {
        id: 5471,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5525,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 5503,
        amount: 1
      },
      {
        id: 159,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5527,
      amount: 1
    },
    skill: 125,
    reagents: [
      {
        id: 5504,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5526,
      amount: 1
    },
    skill: 90,
    reagents: [
      {
        id: 5503,
        amount: 1
      },
      {
        id: 1179,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 6038,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 4655,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 6290,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 6291,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 787,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 6303,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 4592,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 6289,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 6316,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 6317,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 4593,
      amount: 1
    },
    skill: 100,
    reagents: [
      {
        id: 6308,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 5095,
      amount: 1
    },
    skill: 50,
    reagents: [
      {
        id: 6361,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 4594,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 6362,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 6657,
      amount: 1
    },
    skill: 85,
    reagents: [
      {
        id: 6522,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 6890,
      amount: 1
    },
    skill: 40,
    reagents: [
      {
        id: 3173,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 7676,
      amount: 1
    },
    skill: 60,
    reagents: [
      {
        id: 2452,
        amount: 1
      },
      {
        id: 159,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 10841,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 3821,
        amount: 1
      },
      {
        id: 159,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12209,
      amount: 1
    },
    skill: 125,
    reagents: [
      {
        id: 1015,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12210,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 12184,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13851,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 12203,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12212,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 12202,
        amount: 1
      },
      {
        id: 159,
        amount: 1
      },
      {
        id: 4536,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12213,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 12037,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12214,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 12037,
        amount: 1
      },
      {
        id: 2596,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12217,
      amount: 1
    },
    skill: 240,
    reagents: [
      {
        id: 12037,
        amount: 1
      },
      {
        id: 4402,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12215,
      amount: 1
    },
    skill: 200,
    reagents: [
      {
        id: 12204,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      },
      {
        id: 159,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12216,
      amount: 1
    },
    skill: 225,
    reagents: [
      {
        id: 12206,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12218,
      amount: 1
    },
    skill: 225,
    reagents: [
      {
        id: 12207,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 12224,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 12223,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 6887,
      amount: 1
    },
    skill: 225,
    reagents: [
      {
        id: 4603,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13927,
      amount: 1
    },
    skill: 225,
    reagents: [
      {
        id: 13754,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13928,
      amount: 1
    },
    skill: 240,
    reagents: [
      {
        id: 13755,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13930,
      amount: 1
    },
    skill: 225,
    reagents: [
      {
        id: 13758,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13929,
      amount: 1
    },
    skill: 240,
    reagents: [
      {
        id: 13756,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13931,
      amount: 1
    },
    skill: 250,
    reagents: [
      {
        id: 13759,
        amount: 1
      },
      {
        id: 159,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13932,
      amount: 1
    },
    skill: 250,
    reagents: [
      {
        id: 13760,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13933,
      amount: 1
    },
    skill: 275,
    reagents: [
      {
        id: 13888,
        amount: 1
      },
      {
        id: 159,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13934,
      amount: 1
    },
    skill: 275,
    reagents: [
      {
        id: 13893,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 13935,
      amount: 1
    },
    skill: 275,
    reagents: [
      {
        id: 13889,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 16766,
      amount: 1
    },
    skill: 225,
    reagents: [
      {
        id: 7974,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      },
      {
        id: 1179,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 8364,
      amount: 1
    },
    skill: 215,
    reagents: [
      {
        id: 8365,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 17197,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 6889,
        amount: 1
      },
      {
        id: 17194,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 17198,
      amount: 1
    },
    skill: 75,
    reagents: [
      {
        id: 6889,
        amount: 1
      },
      {
        id: 1179,
        amount: 1
      },
      {
        id: 17196,
        amount: 1
      },
      {
        id: 17194,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 17222,
      amount: 1
    },
    skill: 200,
    reagents: [
      {
        id: 12205,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 18045,
      amount: 1
    },
    skill: 225,
    reagents: [
      {
        id: 12208,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 18254,
      amount: 1
    },
    skill: 275,
    reagents: [
      {
        id: 18255,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 20074,
      amount: 1
    },
    skill: 150,
    reagents: [
      {
        id: 3667,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 20452,
      amount: 1
    },
    skill: 285,
    reagents: [
      {
        id: 20424,
        amount: 1
      },
      {
        id: 3713,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 21023,
      amount: 1
    },
    skill: 300,
    reagents: [
      {
        id: 2692,
        amount: 1
      },
      {
        id: 9061,
        amount: 1
      },
      {
        id: 8150,
        amount: 1
      },
      {
        id: 21024,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 21072,
      amount: 1
    },
    skill: 80,
    reagents: [
      {
        id: 21071,
        amount: 1
      },
      {
        id: 2678,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 21217,
      amount: 1
    },
    skill: 175,
    reagents: [
      {
        id: 21153,
        amount: 1
      },
      {
        id: 2692,
        amount: 1
      }
    ]
  }
];
