import React from "react";
import { User } from "App";

import "styles/account.css";

interface AccountPageProps {
  user: User | null;
}

export default function Account(props: AccountPageProps) {
  if (!props.user) {
    return <div>Loading...</div>;
  }

  return (
    <section className="section" id="account-info">
      <div className="container">
        <h1 className="title">My Account</h1>
        <div>Email: {props.user.email}</div>
      </div>
    </section>
  );
}
