import { Recipe } from "types";

export const LeatherworkingRecipes: Recipe[] = [
  {
    result: {
      id: 16984,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 15416,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15052,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15416,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15051,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15416,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19688,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 19768,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19689,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 19768,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20295,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15415,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22761,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22760,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 18512,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22759,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18509,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12607,
        amount: 1,
      },
      {
        id: 15416,
        amount: 1,
      },
      {
        id: 15414,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19157,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 12607,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18251,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19162,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15062,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15417,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20380,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 20381,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15068,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15422,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18504,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19058,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15081,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18510,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 18512,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22665,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22664,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22666,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19149,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15059,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19163,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16983,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18506,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 11754,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15138,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 15410,
        amount: 1,
      },
      {
        id: 14044,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22663,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22662,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22661,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19687,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 19767,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19686,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 19767,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19685,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 19767,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15047,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15414,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15090,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15095,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15096,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20476,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 20501,
        amount: 1,
      },
      {
        id: 20498,
        amount: 1,
      },
      {
        id: 18512,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20478,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 20501,
        amount: 1,
      },
      {
        id: 20498,
        amount: 1,
      },
      {
        id: 18512,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20477,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 20501,
        amount: 1,
      },
      {
        id: 20498,
        amount: 1,
      },
      {
        id: 18512,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18511,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 12753,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20481,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 20500,
        amount: 1,
      },
      {
        id: 20498,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20479,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 20500,
        amount: 1,
      },
      {
        id: 20498,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20480,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 20500,
        amount: 1,
      },
      {
        id: 20498,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21278,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18508,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 18512,
        amount: 1,
      },
      {
        id: 15420,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19049,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15055,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },

  {
    result: {
      id: 15085,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15088,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15049,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15415,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16982,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15070,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15422,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15080,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15058,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15050,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15416,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15075,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15423,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19052,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15063,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15417,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15066,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15420,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19044,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15094,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15087,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15048,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15415,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15069,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15422,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15079,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15060,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15056,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15053,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15065,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15419,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18258,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 18240,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15072,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15423,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20296,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15412,
        amount: 1,
      },
      {
        id: 15407,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15082,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15093,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15086,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15073,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15423,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15071,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15422,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15078,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15092,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7971,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15057,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15064,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15419,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15046,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15412,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15067,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15420,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15061,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15091,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14047,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15054,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7075,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15074,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15423,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15076,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15084,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15045,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15412,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15083,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8367,
      amount: 1,
    },
    skill: 255,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8165,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15077,
      amount: 1,
    },
    skill: 255,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 15408,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8349,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8168,
        amount: 1,
      },
      {
        id: 7971,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8348,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 7075,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8208,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8154,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8215,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },

  {
    result: {
      id: 8212,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15564,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 8170,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15407,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 8171,
        amount: 1,
      },
      {
        id: 15409,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8170,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8206,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8154,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8213,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8216,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8152,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8202,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8152,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8207,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8154,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8197,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8209,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8154,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8185,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8167,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8346,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 7079,
        amount: 1,
      },
      {
        id: 7075,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8193,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8191,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8167,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8347,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8165,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8217,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
      {
        id: 8949,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8218,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
      {
        id: 8951,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8204,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8154,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8214,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8211,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8345,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8368,
        amount: 1,
      },
      {
        id: 8146,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8201,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8151,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8205,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8154,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8203,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8154,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8210,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 8172,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8200,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8151,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8192,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 4338,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8198,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8167,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8189,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8167,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8176,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8175,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8187,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 8167,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4264,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4096,
        amount: 1,
      },
      {
        id: 5633,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8174,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7390,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 7428,
        amount: 1,
      },
      {
        id: 3824,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7391,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2459,
        amount: 1,
      },
      {
        id: 4337,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8173,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18238,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 7428,
        amount: 1,
      },
      {
        id: 7971,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 1210,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4304,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 8172,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 8169,
        amount: 1,
      },
      {
        id: 8150,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7387,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4260,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5739,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17721,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7386,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 7392,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5966,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5783,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 5785,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7378,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4262,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 5500,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },

  {
    result: {
      id: 5965,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7377,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 7070,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4259,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5964,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7374,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 3824,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7375,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 7392,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4256,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 3824,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5963,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 1206,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4258,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5782,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 5785,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7373,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4456,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 4461,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4455,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 4461,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4257,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5962,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18948,
      amount: 1,
    },
    skill: 155,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 4236,
        amount: 1,
      },
      {
        id: 5498,
        amount: 1,
      },
      {
        id: 4461,
        amount: 1,
      },
      {
        id: 5637,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4255,
      amount: 1,
    },
    skill: 155,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4254,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 5637,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4265,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7372,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7371,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 3719,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4236,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4235,
        amount: 1,
      },
      {
        id: 4289,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4234,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18662,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7359,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4247,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4252,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 3390,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7358,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 5373,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7352,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7349,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 3356,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4253,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 4233,
        amount: 1,
      },
      {
        id: 3389,
        amount: 1,
      },
      {
        id: 3182,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4251,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 4233,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4249,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4246,
        amount: 1,
      },
      {
        id: 4233,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7348,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 5116,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4248,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2312,
        amount: 1,
      },
      {
        id: 4233,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4250,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 3383,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7285,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2457,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7284,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 7287,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2314,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5961,
      amount: 1,
    },
    skill: 115,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6468,
      amount: 1,
    },
    skill: 115,
    reagents: [
      {
        id: 6471,
        amount: 1,
      },
      {
        id: 6470,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2316,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6467,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 6471,
        amount: 1,
      },
      {
        id: 6470,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5958,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7283,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 7286,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20575,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 7286,
        amount: 1,
      },
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2315,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2317,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4244,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 4243,
        amount: 1,
      },
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },

  {
    result: {
      id: 4233,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 4232,
        amount: 1,
      },
      {
        id: 4289,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2313,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2319,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7282,
      amount: 1,
    },
    skill: 95,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5781,
      amount: 1,
    },
    skill: 95,
    reagents: [
      {
        id: 5784,
        amount: 1,
      },
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6466,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 6470,
        amount: 1,
      },
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2307,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5780,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 5784,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6709,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 5498,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4243,
      amount: 1,
    },
    skill: 85,
    reagents: [
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2308,
      amount: 1,
    },
    skill: 85,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4246,
      amount: 1,
    },
    skill: 80,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4242,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2312,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 4231,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7281,
      amount: 1,
    },
    skill: 70,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2310,
      amount: 1,
    },
    skill: 60,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2311,
      amount: 1,
    },
    skill: 60,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2309,
      amount: 1,
    },
    skill: 55,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4239,
      amount: 1,
    },
    skill: 55,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2300,
      amount: 1,
    },
    skill: 40,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5081,
      amount: 1,
    },
    skill: 40,
    reagents: [
      {
        id: 5082,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7280,
      amount: 1,
    },
    skill: 35,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4231,
      amount: 1,
    },
    skill: 35,
    reagents: [
      {
        id: 783,
        amount: 1,
      },
      {
        id: 4289,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7278,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7279,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4237,
      amount: 1,
    },
    skill: 25,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2303,
      amount: 1,
    },
    skill: 15,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2302,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7277,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7276,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5957,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2304,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2318,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2934,
        amount: 1,
      },
    ],
  },
];
