import React from "react";
import itemData from "data/items.json";
import spellData from "data/spells.json";
import { ItemStack, ItemData } from "types";
import classNames from "classnames";

import "styles/item.css";

interface ItemIconProps {
  item: ItemStack;
  showName: boolean;
}

function itemFromId(id: number): ItemData {
  const item: ItemData = (itemData as any)[id];
  return item;
}

function spellFromId(id: number): ItemData {
  const item: ItemData = (spellData as any)[id];
  return item;
}

export default function ItemIcon(props: ItemIconProps) {
  const item = itemFromId(props.item.id);

  if (!item || props.item.isSpell) {
    console.log("Falling back to spell data");

    const spell = spellFromId(props.item.id);
    console.log(spell.icon);
    if (!spell) {
      console.log("Yikes");
    }
    const cs = classNames("item", {
      "icon-only": !props.showName,
    });
    return (
      <div className={cs}>
        <a
          href={"https://classic.wowhead.com/spell=" + props.item.id}
          data-wowhead={"item=" + props.item.id}
        >
          <div className="item-icon">
            <img
              width="60px"
              height="60px"
              alt={spell.name + " icon"}
              src={
                process.env.PUBLIC_URL + "/img/icons/Spell_Holy_GreaterHeal.png"
              }
            />
            <span className="amount">{props.item.amount}</span>
          </div>
          {props.showName && <span className="name">{spell.name}</span>}
        </a>
      </div>
    );
  }

  const cs = classNames("item", {
    "icon-only": !props.showName,
  });
  return (
    <div className={cs}>
      <a
        href={"https://classic.wowhead.com/item=" + props.item.id}
        data-wowhead={"item=" + props.item.id}
      >
        <div className="item-icon">
          <img
            width="60px"
            height="60px"
            alt={item.name + " icon"}
            src={process.env.PUBLIC_URL + "/img/icons/" + item.icon + ".png"}
          />
          <span className="amount">{props.item.amount}</span>
        </div>
        {props.showName && <span className="name">{item.name}</span>}
      </a>
    </div>
  );
}
