import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

import { Recipe } from "types";
import ItemIcon from "components/icons/ItemIcon";

import "styles/recipelist.css";

interface RecipeListProps {
  recipes: Recipe[];
  learnedRecipes: number[];
  edit: boolean;
  toggleLearned?: (id: number) => void;
}

interface RecipeListItemProps {
  recipe: Recipe;
  edit: boolean;
  learned: boolean;
  toggleLearned?: (id: number) => void;
}

function RecipeListItem(props: RecipeListItemProps) {
  const setLearned = props.edit ? (
    <span
      className="right"
      onClick={() => {
        props.toggleLearned!(props.recipe.result.id);
      }}
    >
      <FontAwesomeIcon icon={faBook} />
    </span>
  ) : null;

  const c = classnames("recipe-list-item", {
    learned: props.learned,
  });

  return (
    <div className={c} key={props.recipe.result.id}>
      <ItemIcon item={props.recipe.result} showName={true} />

      <div className="recipe-reagents">
        {props.recipe.reagents.map((re) => (
          <ItemIcon item={re} showName={false} />
        ))}
      </div>
      <div className="zzz">
        <span className="recipe-skill">{props.recipe.skill}</span>
        {setLearned}
      </div>
    </div>
  );
}

function RecipeList(props: RecipeListProps) {
  const s = props.recipes.map((r) => (
    <RecipeListItem
      recipe={r}
      edit={props.edit}
      toggleLearned={props.toggleLearned}
      learned={props.learnedRecipes.indexOf(r.result.id) !== -1}
    />
  ));
  return <div className="recipe-list">{s}</div>;
}

export default RecipeList;
