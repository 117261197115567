import React from "react";
import { Profession } from "types";
import { professions } from "data/Data";

import "styles/components/ProfessionIcon.css";

interface ProfessionIconProps {
  professionId: number;
  onClick?: (profession?: Profession) => void;
  onClickEmpty?: () => void;
}

export default function ProfessionIcon(props: ProfessionIconProps) {
  if (props.professionId === -1) {
    return (
      <div
        className="profession-holder-empty"
        onClick={() => {
          if (props.onClickEmpty) {
            props.onClickEmpty();
          }
        }}
      >
        None
      </div>
    );
  }

  const prof = professions[props.professionId];

  return (
    <div
      className="profession-holder"
      onClick={() => {
        if (props.onClick) {
          props.onClick(prof);
        }
      }}
    >
      <img
        src={process.env.PUBLIC_URL + "/" + prof.icon}
        className="profession-icon"
        alt={prof.name + " icon"}
      />
    </div>
  );
}
