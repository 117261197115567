import React, { useState } from "react";
import classNames from "classnames";
import { professions } from "data/Data";

function valToInt(val: string): number {
  const i = val.replace(/[^0-9]/g, "");
  return parseInt(i);
}

function cleanValue(val: string): number {
  const input = val.replace(/\D/g, "");
  return Math.max(Math.min(parseInt(input), 300), 0);
}

interface EditProfessionProps {
  isActive: boolean;
  profId: number;
  profSkill: number;
  toggle: any;
  onSave: any;
  onDelete: any;
  secondary?: boolean;
}

export default function EditProfession(props: EditProfessionProps) {
  const cs = classNames("modal", {
    "is-active": props.isActive,
  });

  const id = props.profId === -1 ? 0 : props.profId;

  const [profession, setProfession] = useState(id);
  const [skill, setSkill] = useState(props.profSkill);

  const select = props.secondary ? null : (
    <div>
      Profession:
      <select
        value={profession}
        onChange={(e) => setProfession(parseInt(e.target.value))}
      >
        {professions.map((p) => {
          if (p.secondary) {
            return null;
          }
          return (
            <option value={p.id}>
              {p.name[0].toUpperCase() + p.name.substr(1)}
            </option>
          );
        })}
      </select>
    </div>
  );

  return (
    <div className={cs}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edit Profession</p>
        </header>
        <section className="modal-card-body">
          {select}
          <br />
          Skill level:
          <input
            value={skill}
            onBlur={(e) => setSkill(cleanValue(e.target.value))}
            onChange={(e) => setSkill(valToInt(e.target.value))}
          ></input>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            onClick={() => {
              props.onSave(profession, skill);
              props.toggle(false);
            }}
          >
            Save changes
          </button>
          <button
            className="button"
            onClick={() => {
              props.toggle(false);
            }}
          >
            Cancel
          </button>
          <button
            className="button is-danger"
            onClick={() => {
              props.onDelete();
            }}
          >
            Delete
          </button>
        </footer>
      </div>
    </div>
  );
}
