import React from "react";

import "styles/chartools.css";
import classNames from "classnames";

export interface CharacterEditHeaderProps {
  onSave: any;
  onDelete: any;
  onStar: any;
  isStarred: boolean;
  isEdited: boolean;
  isEditable: boolean;
}

export default function CharacterEditHeader(props: CharacterEditHeaderProps) {
  const saveClasses = classNames("button is-success", {
    "is-outlined": !props.isEdited,
  });

  const saveButton = props.isEditable ? (
    <button
      className={saveClasses}
      onClick={() => {
        props.onSave();
      }}
    >
      Save
    </button>
  ) : null;

  const deleteButton = props.isEditable ? (
    <button
      className="button is-danger is-outlined"
      onClick={() => {
        props.onDelete();
      }}
    >
      Delete
    </button>
  ) : null;

  return (
    <div className="char-tools">
      {saveButton}
      {deleteButton}
      <button
        className="button is-light"
        onClick={() => {
          props.onStar();
        }}
      >
        {props.isStarred ? "Unstar" : "Star"}
      </button>
    </div>
  );
}
