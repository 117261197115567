import { Recipe } from "types";

export const TailoringRecipes: Recipe[] = [
  {
    result: {
      id: 18263,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19059,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18405,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 9210,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22249,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19684,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19683,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19682,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 12804,
        amount: 1,
      },
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14156,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 14344,
        amount: 1,
      },
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18413,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
      {
        id: 12360,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21342,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 17012,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18407,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 12662,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14106,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 12662,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14112,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 12662,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16979,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19165,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 16980,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19156,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 17010,
        amount: 1,
      },
      {
        id: 17011,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22660,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22658,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22654,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22652,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22655,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 22682,
        amount: 1,
      },
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14146,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 9210,
        amount: 1,
      },
      {
        id: 13926,
        amount: 1,
      },
      {
        id: 12364,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18408,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19050,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14155,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14140,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 12800,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18409,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 13926,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18486,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 13926,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14139,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14138,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14152,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7082,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14153,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 12662,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13867,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20539,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 20520,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20537,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 20520,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 20538,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 20520,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22252,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 13468,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22757,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22758,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22756,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 19726,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14154,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 12811,
        amount: 1,
      },
      {
        id: 13926,
        amount: 1,
      },
      {
        id: 9210,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14128,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14130,
      amount: 1,
    },
    skill: 300,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
      {
        id: 7910,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13866,
      amount: 1,
    },
    skill: 295,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 19056,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 13926,
        amount: 1,
      },
      {
        id: 12809,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14104,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14111,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14144,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 9210,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14137,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },

  {
    result: {
      id: 19047,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7076,
        amount: 1,
      },
      {
        id: 12803,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 15802,
      amount: 1,
    },
    skill: 290,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14342,
        amount: 1,
      },
      {
        id: 7971,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21341,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 12810,
        amount: 1,
      },
      {
        id: 20520,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14108,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14136,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 12808,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13865,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 18258,
      amount: 1,
    },
    skill: 285,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 18240,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14045,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13871,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13864,
      amount: 1,
    },
    skill: 280,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14103,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22251,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 8831,
        amount: 1,
      },
      {
        id: 11040,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14044,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14134,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7078,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22248,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 16203,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14107,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14256,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14141,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 9210,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13863,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14132,
      amount: 1,
    },
    skill: 275,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 11176,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14101,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14100,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 3577,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14043,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14142,
      amount: 1,
    },
    skill: 270,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 9210,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13870,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7080,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14143,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 9210,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13860,
      amount: 1,
    },
    skill: 265,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14042,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14046,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13858,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13857,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14227,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21340,
      amount: 1,
    },
    skill: 260,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 8170,
        amount: 1,
      },
      {
        id: 7972,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13868,
      amount: 1,
    },
    skill: 255,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7079,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13869,
      amount: 1,
    },
    skill: 255,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 7079,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 13856,
      amount: 1,
    },
    skill: 255,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14342,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 14256,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10041,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 10286,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 6037,
        amount: 1,
      },
      {
        id: 1529,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21154,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 4625,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 21542,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 14048,
        amount: 1,
      },
      {
        id: 4625,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 14341,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10036,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10040,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 14048,
      amount: 1,
    },
    skill: 250,
    reagents: [
      {
        id: 14047,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10044,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10025,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 10285,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10035,
      amount: 1,
    },
    skill: 245,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10030,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 4589,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10033,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10031,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 10285,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10034,
      amount: 1,
    },
    skill: 240,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10051,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10029,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10028,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 10285,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10055,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 10290,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10053,
      amount: 1,
    },
    skill: 235,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10026,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10024,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10027,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10054,
      amount: 1,
    },
    skill: 230,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 4342,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10042,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 7077,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10019,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 10286,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10021,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8153,
        amount: 1,
      },
      {
        id: 10286,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 22246,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 11137,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10050,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10018,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10023,
      amount: 1,
    },
    skill: 225,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 10285,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10052,
      amount: 1,
    },
    skill: 220,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 6261,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10003,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10009,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10007,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10004,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 10285,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10056,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 6261,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10008,
      amount: 1,
    },
    skill: 215,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10001,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7064,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
      {
        id: 6371,
        amount: 1,
      },
      {
        id: 4304,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10002,
      amount: 1,
    },
    skill: 210,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 10285,
        amount: 1,
      },
      {
        id: 8343,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9999,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 9998,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7063,
      amount: 1,
    },
    skill: 205,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
      {
        id: 3827,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4327,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
      {
        id: 3829,
        amount: 1,
      },
      {
        id: 4337,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4329,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 3864,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4336,
      amount: 1,
    },
    skill: 200,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7061,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7062,
      amount: 1,
    },
    skill: 195,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7060,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 7072,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7059,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 6371,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7054,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 4339,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 7070,
        amount: 1,
      },
      {
        id: 7068,
        amount: 1,
      },
      {
        id: 7069,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 17723,
      amount: 1,
    },
    skill: 190,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5765,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2325,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4326,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 3827,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7058,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4335,
      amount: 1,
    },
    skill: 185,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4342,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7056,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 6371,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7057,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4328,
      amount: 1,
    },
    skill: 180,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4337,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7052,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 7070,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7053,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4325,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
      {
        id: 4337,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7055,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 7071,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5764,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6796,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4339,
      amount: 1,
    },
    skill: 175,
    reagents: [
      {
        id: 4338,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7051,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 7067,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4323,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
      {
        id: 3824,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4334,
      amount: 1,
    },
    skill: 170,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4322,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 4337,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7065,
      amount: 1,
    },
    skill: 165,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7050,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6795,
      amount: 1,
    },
    skill: 160,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
      {
        id: 4291,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4333,
      amount: 1,
    },
    skill: 155,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4324,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5770,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 3182,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4245,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7049,
      amount: 1,
    },
    skill: 150,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 929,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4319,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7047,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4234,
        amount: 1,
      },
      {
        id: 6048,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7048,
      amount: 1,
    },
    skill: 145,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7046,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4321,
      amount: 1,
    },
    skill: 140,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 3182,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5766,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 3182,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4332,
      amount: 1,
    },
    skill: 135,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 4341,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4318,
      amount: 1,
    },
    skill: 130,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 3383,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4331,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 5500,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4317,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 5500,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4320,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4305,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 3182,
        amount: 1,
      },
      {
        id: 5500,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4305,
      amount: 1,
    },
    skill: 125,
    reagents: [
      {
        id: 4306,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10048,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4315,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2319,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6384,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6385,
      amount: 1,
    },
    skill: 120,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6264,
      amount: 1,
    },
    skill: 115,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5763,
      amount: 1,
    },
    skill: 115,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4314,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4316,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4330,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6787,
      amount: 1,
    },
    skill: 110,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2585,
      amount: 1,
    },
    skill: 105,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6263,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4311,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 5498,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2587,
      amount: 1,
    },
    skill: 100,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 4340,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4241,
      amount: 1,
    },
    skill: 95,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4313,
      amount: 1,
    },
    skill: 95,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2583,
      amount: 1,
    },
    skill: 95,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5542,
      amount: 1,
    },
    skill: 90,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 5498,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2582,
      amount: 1,
    },
    skill: 85,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4310,
      amount: 1,
    },
    skill: 85,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4312,
      amount: 1,
    },
    skill: 80,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4240,
      amount: 1,
    },
    skill: 80,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10047,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2584,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 2997,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2997,
      amount: 1,
    },
    skill: 75,
    reagents: [
      {
        id: 2592,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2578,
      amount: 1,
    },
    skill: 70,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6242,
      amount: 1,
    },
    skill: 70,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2579,
      amount: 1,
    },
    skill: 70,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4309,
      amount: 1,
    },
    skill: 70,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 5762,
      amount: 1,
    },
    skill: 70,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2321,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2569,
      amount: 1,
    },
    skill: 65,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4308,
      amount: 1,
    },
    skill: 60,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 2605,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2580,
      amount: 1,
    },
    skill: 60,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6240,
      amount: 1,
    },
    skill: 55,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6239,
      amount: 1,
    },
    skill: 55,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4238,
      amount: 1,
    },
    skill: 45,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2577,
      amount: 1,
    },
    skill: 40,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2572,
      amount: 1,
    },
    skill: 40,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2575,
      amount: 1,
    },
    skill: 40,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 2604,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6786,
      amount: 1,
    },
    skill: 40,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 6260,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4307,
      amount: 1,
    },
    skill: 35,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4343,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6238,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 6241,
      amount: 1,
    },
    skill: 30,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10046,
      amount: 1,
    },
    skill: 20,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2318,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 7026,
      amount: 1,
    },
    skill: 15,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2568,
      amount: 1,
    },
    skill: 10,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 4344,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2570,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 10045,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2576,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2996,
        amount: 1,
      },
      {
        id: 2320,
        amount: 1,
      },
      {
        id: 2324,
        amount: 1,
      },
    ],
  },
  {
    result: {
      id: 2996,
      amount: 1,
    },
    skill: 1,
    reagents: [
      {
        id: 2589,
        amount: 1,
      },
    ],
  },
];
