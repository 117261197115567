import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

import { User } from "App";

import "styles/login.css";

interface LoginProps {
  title: string;
  url: string;
  user: User | null;
}

export function LogIn(props: LoginProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const [redir, setRedir] = useState(props.user ? true : false);
  const history = useHistory();

  useEffect(() => {
    if (props.user) {
      setRedir(true);
    }
  }, [props.user]);

  const canSubmit = (): boolean => {
    return email.length > 3 && password.length >= 8;
  };

  const submit = async () => {
    const resp = await fetch(props.url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    if (resp.ok) {
      setRedir(true);
    } else {
      console.log(resp);
      if (resp.status === 401) {
        const j = await resp.json();
        setError(j["message"]);
      } else {
        const t = await resp.text();
        setError(t);
      }
    }
  };

  if (redir) {
    history.push("/account");
    window.location.reload();
  }

  let errMsg = error ? (
    <article className="message is-danger">
      <div className="message-body">{error}</div>
    </article>
  ) : null;

  return (
    <section className="section">
      <div className="login-holder container">
        {errMsg}
        <h1>{props.title}</h1>
        <div className="field">
          <label htmlFor="email">Email</label>
          <p className="control has-icons-left ">
            <input
              id="email"
              className="input"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            {/* <span className="icon is-small is-right">
              <FontAwesomeIcon icon={faCheck} />
            </span> */}
          </p>
        </div>
        <div className="field">
          <label htmlFor="password">Password</label>
          <p className="control has-icons-left">
            <input
              id="password"
              className="input"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faLock} />
            </span>
          </p>
        </div>

        <div>
          <input
            type="button"
            id="submit"
            value="Submit"
            className="button is-success"
            onClick={submit}
            disabled={!canSubmit()}
          />
        </div>
      </div>
    </section>
  );
}
