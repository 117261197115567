import { Profession, Recipe } from "types";
import { FirstAidRecipes } from "data/recipes/FirstAid";
import { CookingRecipes } from "data/recipes/Cooking";
import { AlchemyRecipes } from "data/recipes/Alchemy";
import { BlacksmithingRecipes } from "data/recipes/Blacksmithing";
import { EnchantingRecipes } from "data/recipes/Enchanting";
import { EngineeringRecipes } from "data/recipes/Engineering";
import { LeatherworkingRecipes } from "data/recipes/Leatherworking";
import { TailoringRecipes } from "data/recipes/Tailoring";

export const Alchemy = {
  id: 0,
  icon: "./img/alchemy.png",
  name: "Alchemy",
  recipes: sortRecipesBySkill(AlchemyRecipes),
};

export const Blacksmithing = {
  id: 1,
  icon: "./img/blacksmithing.png",
  name: "Blacksmithing",
  recipes: sortRecipesBySkill(BlacksmithingRecipes),
};
export const Enchanting = {
  id: 2,
  icon: "./img/enchanting.png",
  name: "Enchanting",
  recipes: sortRecipesBySkill(EnchantingRecipes),
};
export const Engineering = {
  id: 3,
  icon: "./img/engineering.png",
  name: "Engineering",
  recipes: sortRecipesBySkill(EngineeringRecipes),
};
export const Leatherworking = {
  id: 4,
  icon: "./img/leatherworking.png",
  name: "Leatherworking",
  recipes: sortRecipesBySkill(LeatherworkingRecipes),
};
export const Tailoring = {
  id: 5,
  icon: "./img/tailoring.png",
  name: "Tailoring",
  recipes: sortRecipesBySkill(TailoringRecipes),
};

export const Skinning = {
  id: 6,
  icon: "./img/skinning.png",
  name: "Skinning",
  recipes: [],
};
export const Herbalism = {
  id: 7,
  icon: "./img/herbalism.png",
  name: "Herbalism",
  recipes: [],
};
export const FirstAid = {
  id: 8,
  icon: "./img/first_aid.png",
  name: "First Aid",
  recipes: sortRecipesBySkill(FirstAidRecipes),
  secondary: true,
};

export const Cooking: Profession = {
  id: 9,
  icon: "./img/cooking.png",
  name: "Cooking",
  recipes: sortRecipesBySkill(CookingRecipes),
  secondary: true,
};

export const professions: Profession[] = [
  Alchemy,
  Blacksmithing,
  Enchanting,
  Engineering,
  Leatherworking,
  Tailoring,
  Skinning,
  Herbalism,
  FirstAid,
  Cooking,
];

function sortRecipesBySkill(recipes: Recipe[]): Recipe[] {
  return recipes.sort((a, b) => {
    return a.skill - b.skill;
  });
}
