import React from "react";

import "styles/components/RaceIcon.css";

interface RaceIconProps {
  race: string;
  gender: string;
}

export default function RaceIcon(props: RaceIconProps) {
  const valid_genders = ["male", "female"];
  const valid_races = [
    "orc",
    "troll",
    "tauren",
    "forsaken",
    "human",
    "dwarf",
    "gnome",
    "nightelf",
  ];

  let icon = "";
  if (
    valid_genders.indexOf(props.gender) !== -1 &&
    valid_races.indexOf(props.race) !== -1
  ) {
    icon = `/img/races/race_${props.race}_${props.gender}.jpg`;
  } else {
    icon = "/img/races/race_unknown.png";
  }

  return (
    <img
      className="race-icon"
      src={process.env.PUBLIC_URL + icon}
      width={32}
      height={32}
      alt="race icon"
    />
  );
}
