import { Recipe } from "types";

export const FirstAidRecipes: Recipe[] = [
  {
    result: {
      id: 19440,
      amount: 1
    },
    skill: 300,
    reagents: [
      {
        id: 19441,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 14530,
      amount: 1
    },
    skill: 290,
    reagents: [
      {
        id: 14047,
        amount: 2
      }
    ]
  },
  {
    result: {
      id: 14529,
      amount: 1
    },
    skill: 260,
    reagents: [
      {
        id: 14047,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 8545,
      amount: 1
    },
    skill: 240,
    reagents: [
      {
        id: 4338,
        amount: 2
      }
    ]
  },
  {
    result: {
      id: 8544,
      amount: 1
    },
    skill: 210,
    reagents: [
      {
        id: 4338,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 6451,
      amount: 1
    },
    skill: 180,
    reagents: [
      {
        id: 4306,
        amount: 2
      }
    ]
  },
  {
    result: {
      id: 6450,
      amount: 1
    },
    skill: 150,
    reagents: [
      {
        id: 4306,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 6453,
      amount: 1
    },
    skill: 130,
    reagents: [
      {
        id: 1288,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3531,
      amount: 1
    },
    skill: 115,
    reagents: [
      {
        id: 2592,
        amount: 2
      }
    ]
  },
  {
    result: {
      id: 6452,
      amount: 3
    },
    skill: 80,
    reagents: [
      {
        id: 1475,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 3530,
      amount: 1
    },
    skill: 80,
    reagents: [
      {
        id: 2592,
        amount: 1
      }
    ]
  },
  {
    result: {
      id: 2581,
      amount: 1
    },
    skill: 40,
    reagents: [
      {
        id: 2589,
        amount: 2
      }
    ]
  },
  {
    result: {
      id: 1251,
      amount: 1
    },
    skill: 1,
    reagents: [
      {
        id: 2589,
        amount: 1
      }
    ]
  }
];
