import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import RecipeList from "components/RecipeList";
import ProfessionHeader from "components/ProfessionHeader";
import CharacterEditHeader from "components/CharacterEditHeader";
import RaceIcon from "components/icons/RaceIcon";
import ProfessionIcon from "components/icons/ProfessionIcon";
import { professions, Cooking, FirstAid } from "data/Data";
import EditProfession from "components/EditProfession";
import { Character } from "types";

import "styles/characterview.scss";
import { User } from "App";

function toggleElement<T>(el: T, arr: T[]): T[] {
  const index = arr.indexOf(el);

  if (index !== -1) {
    return arr.filter((_, i) => i !== index);
  }

  return arr.concat([el]);
}

async function exportToServer(
  char: Character,
  primary1: number,
  primary2: number,
  data: string,
  callback: any
) {
  const resp = await fetch("https://api.professions.io/v0/character", {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: char.id,
      name: char.name,
      realm: char.realm,
      race: char.race,
      gender: char.gender,
      primary1: primary1,
      primary2: primary2,
      data: data,
    }),
  });
  if (resp.ok) {
    callback();
  }
}

interface CharacterViewProps {
  user: User | null;
}

export default function CharacterView(props: CharacterViewProps) {
  let { id } = useParams();

  // This has the assumption that recipes and crafted items are 1-1, i.e. no two recipes make the same item
  // This is NOT a correct assumption, but it's correct enough for now.
  const [learnedRecipes, setLearned] = useState<Array<number>>([]);
  const [skillLevels, setSkills] = useState<Array<number>>(
    new Array(10).fill(0)
  );
  const [profs, setProfs] = useState<Array<number>>([
    -1,
    -1,
    Cooking.id,
    FirstAid.id,
  ]);
  const [currProf, setCurrProf] = useState(-1);
  const [currSlot, setCurrSlot] = useState(0);

  const [addProfModal, setProfModal] = useState(false);

  const [character, setCharacter] = useState<Character | null>(null);
  const [isStarred, setStarred] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const prof = professions[currProf];

  useEffect(() => {
    async function loadCharacter() {
      const resp = await fetch(`https://api.professions.io/v0/c/${id}`);
      if (resp.ok) {
        const json = await resp.json();
        setCharacter(json);
        setProfs([json.primary1, json.primary2, Cooking.id, FirstAid.id]);
        console.log(json);
        const data = JSON.parse(json.data);
        const skills = new Array(10).fill(0);
        skills[json.primary1] = data["skills"][0];
        skills[json.primary2] = data["skills"][1];
        skills[Cooking.id] = data["skills"][2];
        skills[FirstAid.id] = data["skills"][3];
        setSkills(skills);
        setLearned(data.learned);
        setTimeout(() => {
          setIsEdited(false);
        }, 100);
      } else {
        console.log(resp);
        console.log("error");
      }
    }

    loadCharacter();
  }, [id]);

  useEffect(() => {
    setIsEdited(true);
  }, [learnedRecipes, profs, skillLevels]);

  useEffect(() => {
    async function getStarred() {
      const resp = await fetch(`https://api.professions.io/v0/star/${id}`, {
        credentials: "include",
      });

      if (resp.ok) {
        const t = await resp.text();

        if (t === "true") {
          setStarred(true);
        }
      }
    }

    if (props.user) {
      getStarred();
    }
  }, [id, props.user]);

  useEffect(() => {
    setCurrProf(profs[currSlot]);
  }, [profs, currSlot]);

  useEffect(() => {
    if (props.user && character && character.owner) {
      if (props.user.id === character.owner) {
        setIsEditable(true);
      }
    }
  }, [character, props.user]);

  const onSave = (prof: number, skill: number) => {
    setSkills(
      skillLevels.map((v, ind) => {
        if (ind !== prof) {
          return v;
        } else {
          return skill;
        }
      })
    );

    setProfs(
      profs.map((p, i) => {
        if (i !== currSlot) {
          return p;
        } else {
          return prof;
        }
      })
    );
  };

  const onDelete = () => {
    setProfs(
      profs.map((p, i) => {
        if (i !== currSlot) {
          return p;
        } else {
          return -1;
        }
      })
    );
  };

  if (id === null) {
    return <div>You must supply a valid character ID!</div>;
  }

  if (character === null) {
    return <div>Loading...</div>;
  }

  let list, header;
  if (currProf >= 0) {
    list = (
      <RecipeList
        learnedRecipes={learnedRecipes}
        recipes={professions[currProf].recipes}
        edit={isEditable}
        toggleLearned={(el) => {
          setLearned(toggleElement(el, learnedRecipes));
        }}
      />
    );
    header = (
      <ProfessionHeader
        profession={prof}
        onSave={onSave}
        onDelete={onDelete}
        skillLevel={skillLevels[currProf]}
        owner={isEditable}
      />
    );
  }

  const icons = profs.map((p, i) => {
    if (p === undefined) {
      return null;
    }

    return (
      <ProfessionIcon
        professionId={p}
        onClick={() => {
          setCurrProf(p);
          setCurrSlot(i);
        }}
        onClickEmpty={() => {
          setCurrSlot(i);
          setProfModal(true);
        }}
      />
    );
  });

  const m = setProfModal ? (
    <EditProfession
      profId={-1}
      profSkill={0}
      secondary={false}
      isActive={addProfModal}
      toggle={setProfModal}
      onSave={onSave}
      onDelete={onDelete}
    />
  ) : null;

  return (
    <div className="container character-view">
      <CharacterEditHeader
        onSave={() => {
          const data = JSON.stringify({
            skills: [
              skillLevels[profs[0]],
              skillLevels[profs[1]],
              skillLevels[profs[2]],
              skillLevels[profs[3]],
            ],
            learned: learnedRecipes,
          });
          exportToServer(character, profs[0], profs[1], data, () => {
            setIsEdited(false);
          });
        }}
        onStar={() => {
          toggleStarred(id);
          setStarred(!isStarred);
        }}
        onDelete={() => {
          async function deleteChar() {
            if (!character) {
              return;
            }
            const resp = await fetch(
              `https://api.professions.io/v0/c/${character.id}/del`,
              {
                method: "DELETE",
                credentials: "include",
              }
            );

            if (resp.ok) {
              window.location.replace("/characters");
            }
          }

          const d = window.confirm("Delete character? This cannot be undone");
          if (d) {
            deleteChar();
          }
        }}
        isStarred={isStarred}
        isEdited={isEdited}
        isEditable={isEditable}
      />
      {m}
      <div className="character-header">
        <CharacterInfo {...character} />
        <div className="chracter-professions">{icons}</div>
      </div>
      <div>{header}</div>
      <div>{list}</div>
    </div>
  );
}

function CharacterInfo(char: Character) {
  return (
    <div className="character-info">
      <RaceIcon race={char.race} gender={char.gender} />
      <h1 className="title">{char.name}</h1>
      <h2 className="subtitle">({char.realm})</h2>
    </div>
  );
}

async function toggleStarred(id: string) {
  const resp = await fetch(`https://api.professions.io/v0/star/${id}`, {
    method: "POST",
    credentials: "include",
  });
  console.log(resp);
}
