import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import EditCharacter from "components/EditCharacter";
import RaceIcon from "components/icons/RaceIcon";
import ProfessionIcon from "components/icons/ProfessionIcon";
import { User } from "App";
import { Character } from "types";

import "styles/characterlist.css";

function CharacterCard(c: Character) {
  return (
    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              <RaceIcon race={c.race} gender={c.gender} />
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-4">
              {c.name}{" "}
              <a href={`/c/${c.id}`}>
                <FontAwesomeIcon icon={faLink} />
              </a>
            </p>
            <p className="subtitle is-6">{c.realm}</p>
          </div>
        </div>

        <div className="content">
          <ProfessionIcon professionId={c.primary1} />
          <ProfessionIcon professionId={c.primary2} />
        </div>
      </div>
    </div>
  );
}

interface CharacterListProps {
  user: User | null;
}

function CharacterList(props: CharacterListProps) {
  const [isCreating, setIsCreating] = useState(false);
  const [charList, setCharList] = useState([]);
  const [starList, setStarList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getCharacters() {
      const resp = await fetch("https://api.professions.io/v0/chars", {
        credentials: "include",
      });

      console.log(resp);
      if (resp.ok) {
        const json = await resp.json();
        const r = json.map((c: Character) => <CharacterCard {...c} />);
        setCharList(r);
      }

      setLoading(false);
    }

    async function getStarred() {
      const resp = await fetch("https://api.professions.io/v0/stars", {
        credentials: "include",
      });

      console.log(resp);
      if (resp.ok) {
        const json = await resp.json();
        const r = json.map((c: Character) => <CharacterCard {...c} />);
        setStarList(r);
      }

      setLoading(false);
    }

    getCharacters();
    getStarred();
  }, [props.user]);

  const modal = isCreating ? (
    <EditCharacter isActive={true} setOpen={setIsCreating} />
  ) : null;

  if (!!!props.user) {
    return (
      <div id="character-list" className="container needs-login">
        You need to be logged in
      </div>
    );
  }

  const list = loading ? (
    <div>Loading...</div>
  ) : (
    <div className="characters">{charList}</div>
  );

  return (
    <div id="character-list" className="container">
      {modal}
      <section>
        <div className="my-chars-header">
          <h1 className="title">My Characters</h1>
          <input
            type="button"
            className="button is-success"
            value="Create Character"
            onClick={() => {
              setIsCreating(true);
            }}
          />
        </div>
        {list}
      </section>
      <section>
        <div className="starred-header">
          <h1 className="title">Starred</h1>
        </div>
        <div className="characters">{starList}</div>
      </section>
    </div>
  );
}

export default CharacterList;
